/* eslint-disable class-methods-use-this */
import React from 'react';
import { Auth } from 'aws-amplify';
import getEnvLabel from 'helpers/GetEnvLabel';
import logo from '../styles/images/logo.svg';

class VendorLogin extends React.Component {
  state = {
    username: '',
    password: '',
    loading: false,
    loginError: false,
    errorMsg: ''
  };

  validate = (email, password) => {
    return !(email.length === 0 || password.length === 0);
  };

  handleSignIn = async e => {
    const { username, password } = this.state;
    const { history } = this.props;

    e.preventDefault();

    this.setState({ loading: true });
    if (this.validate(username, password)) {
      try {
        const user = await Auth.signIn(username, password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          Auth.completeNewPassword(user, password, { email: username });
        }
        history.push('/suite/dashboard');
      } catch (error) {
        this.setState({
          errorMsg: 'Username or password is incorrect!',
          loginError: true,
          loading: false
        });
      }
    } else {
      this.setState({
        loading: false,
        loginError: true,
        errorMsg: 'Username & password are required!'
      });
    }
  };

  render() {
    const { username, password } = this.state;
    const { errorMsg, loading, loginError } = this.state;

    let loaderOrSubmitBtn;

    if (loading) {
      loaderOrSubmitBtn = <div className="login-loader spinner"></div>;
    } else {
      loaderOrSubmitBtn = (
        <input type="submit" tabIndex="0" className="button loginbtn hover-brighten-focus" value="Login" />
      );
    }

    const envLogo = getEnvLabel();

    return (
      <div className={`wrapper login-wrapper ${envLogo}`}>
        <div className="login-panel">
          <img src={logo} alt="Sysco Supplier Suite" className="logo" />

          <div className="title">Please login to continue.</div>
          <form onSubmit={this.handleSignIn} id="form-id">
            <div className="textfield-group">
              <input
                name="username"
                id="username"
                type="text"
                onChange={e => {
                  this.setState({ username: e.target.value });
                }}
                value={username}
                placeholder="Username"
              />
            </div>
            <div className="textfield-group">
              <input
                id="password"
                type="password"
                value={password}
                onChange={e => {
                  this.setState({ password: e.target.value });
                }}
                placeholder="Password"
              />
            </div>
            <div className="login-options">
              <label htmlFor="toggle-label" className="toggle">
                <input id="remember-checkbox" className="toggle-checkbox" type="checkbox" />
                <div className="toggle-switch"></div>
                <span className="toggle-label">Remember me</span>
              </label>
              <a href="/vendorlogin" className="forgot-password hide">
                Forgot your password?
              </a>
            </div>
            {loginError ? <div className="validations">{errorMsg}</div> : null}
            {loaderOrSubmitBtn}
          </form>
        </div>
      </div>
    );
  }
}

export default VendorLogin;
