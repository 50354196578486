import Axios from 'axios';
import { Auth } from 'aws-amplify';
import { split } from 'lodash';

const cognitoDefaultUserRole = process.env.REACT_APP_COGNITO_DEFAULT_USER_ROLE;
export const baseURL = process.env.REACT_APP_USER_MANAGEMENT_BFF;
const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getIdToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  return token;
};

export const getRequestHeaders = async () => ({
  Authorization: `Bearer ${await getIdToken()}`,
  'Content-Type': 'application/json'
});

export const getUserRole = userInQuestion => {
  // TODO: remove once removing role from token is confirmed
  const userGrps = userInQuestion.signInUserSession.idToken.payload['cognito:groups'];
  if (userGrps && !(userGrps.length === 1 && userGrps[0] === cognitoDefaultUserRole)) {
    return userGrps.filter(role => role !== cognitoDefaultUserRole)[0];
  }
  return null;
};

const getSyscoUserId = username => {
  if (username.indexOf(adPrefix) >= 0) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  if (username.indexOf(oktaPrefix) >= 0) {
    return username.replace(oktaPrefix, '');
  }
  return username;
};

export const getUser = async () => {
  const {
    user: { username: userId }
  } = Auth;

  let user = {
    details: {},
    vendor: {
      vendorId: null,
      vendorName: null
    },
    userRole: null
  };

  const id = getSyscoUserId(userId);

  try {
    const { data: userDetails } = await Axios.get(`${baseURL}/users/username/${id}`, {
      headers: await getRequestHeaders()
    });

    user = { details: userDetails, userRole: userDetails.userType };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  return user;
};
