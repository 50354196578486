import React from 'react';
import { Dropdown, Menu } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';

const mailHandlerComponent = ({ title, email }) => (
  <a href={`mailto:${email}`}>
    <div className="support-contact-title">{title}</div>
    <div className="support-contact-email">{email}</div>
  </a>
);

function SupportContact({ isSupportContactVisible, supportContactDetails }) {
  if (isSupportContactVisible && supportContactDetails?.length > 0) {
    const contactMenu = (
      <Menu
        items={supportContactDetails.map(({ key, title, email } = {}) => ({
          className: 'mail-handler',
          key,
          label: mailHandlerComponent({ title, email }),
          icon: <UserOutlined style={{ fontSize: '1.3em', marginRight: '1em' }} />
        }))}
      />
    );

    return (
      <Dropdown arrow overlay={contactMenu} placement="bottom" id="support-contact-drop-down">
        <MailOutlined style={{ fontSize: '1.6em' }} />
      </Dropdown>
    );
  }

  // if not visible or empty contacts, then view empty react fragments
  return <></>;
}

export default SupportContact;
