/* eslint-disable no-unused-vars */
import React from 'react';
import { Auth } from 'aws-amplify';
import UserContext from 'context/User';
import { withTranslation } from 'react-i18next';

class UserWidget extends React.Component {
  state = {
    visible: false
  };

  toggleMenu = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  changeLanguage = lng => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  };

  // eslint-disable-next-line class-methods-use-this
  logOut = async () => {
    await Auth.signOut();
    // FIXME: when find out how to unmount a parcel
    window.location.reload();
  };

  static contextType = UserContext;

  render() {
    const { visible } = this.state;
    const { userDetails, userRole } = this.context;
    const { t, isBackgroundSpacer } = this.props;

    const roleName = userRole?.roleName || userDetails.userType || '';

    return (
      <React.Fragment>
        <div id="user-widget" className={'bg-spacer '.concat(visible ? 'user-widget show' : 'user-widget')}>
          <div className="user">
            <div className="name">
              {userDetails.fullName ? <span className="welcome">{`${t('landing.welcome')} `}</span> : null}
              {userDetails.fullName ? userDetails.fullName.toUpperCase() : null}
            </div>
            {roleName ? <div className="type">{roleName.toUpperCase()}</div> : null}
          </div>
          <div
            id="user-pic"
            className="user-pic hover-brighten"
            role="button"
            tabIndex="0"
            onKeyPress={this.toggleMenu}
            onClick={this.toggleMenu}
          >
            <div className="pic"></div>
            <span className="menuicon fi flaticon-caret-down " />
          </div>
          {visible && (
            <div id="account-menu" className="account-menu show" role="button" tabIndex="-1" onClick={this.toggleMenu}>
              <ul>
                <li className="hide" id="ddl-change-profile">
                  <span className="icon fi flaticon-user" />
                  {t('landing.profile')}
                </li>
                <li className="hide" id="ddl-change-settings">
                  <span className="icon fi flaticon-gear" />
                  {t('landing.settings')}
                </li>
                <li id="ddl-change-language">
                  <span className="icon fi flaticon-subject" />
                  <div>
                    <div>{t('landing.changeLanguage')}</div>
                    <div className="sub-text">{t('landing.setLanguage')}</div>
                  </div>
                  <div className="submenu">
                    <ul>
                      <li tabIndex="-2" onClick={() => this.changeLanguage('en')} id="ddl-change-language-en">
                        <span className="linkbutton">English</span>
                      </li>
                      <li tabIndex="-3" onClick={() => this.changeLanguage('fr')} id="ddl-change-language-fr">
                        <span className="linkbutton">French</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="hide" id="ddl-help">
                  <span className="icon fi flaticon-question-1" />
                  {t('landing.help')}
                </li>
                <li onClick={this.logOut} id="ddl-logout">
                  <span className="icon fi flaticon-logout" />
                  {t('landing.logOut')}
                </li>
              </ul>
            </div>
          )}
          {visible && (
            <div role="button" tabIndex="-4" className="backgroundClickContainer" onClick={this.toggleMenu} />
          )}
        </div>
        <div role="button" tabIndex="-4" className="sidemenu-toggle" onClick={this.toggleMenu}>
          <i className="fi flaticon-menu"></i>
        </div>
        <div
          role="button"
          tabIndex="-5"
          className={visible ? 'sidemenu-closer show' : 'sidemenu-closer'}
          onClick={this.toggleMenu}
        >
          <i className="fi flaticon-close"></i>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(UserWidget);
