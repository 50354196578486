import { useQuery } from 'react-query';
import openErrorNotification from 'helpers/NotificationUtils';
import { getUserApplicationMessages } from 'helpers/QueryUtils';

/**
 * This file contains Custom Hooks that involve `react-query` useQuery hooks
 * Queries execute whenever any of the values in the useQuery dependency array changes
 */

export default function queryApplicationMessages(roleId) {
  const { data, isLoading } = useQuery([roleId], getUserApplicationMessages, {
    onError: () => {
      openErrorNotification({ message: 'landing.applications.messages.fetchError' });
    }
  });

  return { applicationMessages: data || [], isLoading };
}
