/**
 * change language according to user details.
 */
const getLanguage = lang => {
  let language;
  switch (lang) {
    case 'EN':
      language = 'en';
      break;
    case 'FR':
      language = 'fr';
      break;
    default:
      language = 'en';
      break;
  }
  return language;
};

export default getLanguage;
