import React from 'react';
import PropTypes from 'prop-types';
import logo from 'styles/images/logo.svg';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CostingAppLogo from 'styles/images/app-logos/costing-logo.svg';
import i18n from '_i18n';
// import ClaimsAppLogo from 'styles/images/app-logos/claims-logo.svg';
// import UserManagementAppLogo from 'styles/images/app-logos/usermanagement-logo.svg';
// import ItemEnrichmentAppLogo from 'styles/images/app-logos/item-enrichment-logo.svg';
import getEnvLabel from 'helpers/GetEnvLabel';
import UserWidget from './UserWidget';
import VendorSwitcher from './VendorSwitcher';
import SupportContact from './SupportContact';
// import AppDrawerDropdown from './AppDrawerDropdown';
// import NotificationsDrawer from './NotificationsDrawer';

function AppBar({ selectVendor, isSupportContactVisible, supportContactDetails }) {
  // const { userRole } = useContext(AuthContext);
  const envLogo = getEnvLabel();
  // const { selectedVendor, userRole } = useContext(UserContext);

  return (
    <div id="app-bar" className="app-bar">
      <div className={`branding ${envLogo}`}>
        <a href="/suite/dashboard">
          <img src={logo} id="supplier-suite-logo" className="logo" alt="" />
        </a>
        <div className="app-logo-wrapper">
          {/* do not add additional logos here. logos should be injected from the micro fe */}
          <img src={CostingAppLogo} id="app-logo" alt="App Logo" className="app-logo" />
        </div>
      </div>
      <VendorSwitcher selectVendor={selectVendor} />
      <div className="spacer" />
      <div className="account-widget">
        <div className="messages hide">
          <div className="label">Message</div>
          <span className="icon fi flaticon-mail" />
        </div>
        {/* <NotificationsDrawer />
        <AppDrawerDropdown /> */}
        <Link title={i18n.t('landing.help')} to={{ pathname: `/suite/help` }}>
          <QuestionCircleOutlined className="help-icon" />
        </Link>
        <SupportContact
          isSupportContactVisible={isSupportContactVisible}
          supportContactDetails={supportContactDetails}
        />
        <UserWidget selectVendor={selectVendor} />
      </div>
    </div>
  );
}

AppBar.propTypes = {
  selectVendor: PropTypes.func
};

export default AppBar;
