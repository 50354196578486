import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Auth, Hub, Logger } from 'aws-amplify';
import LoginPage from 'pages/LoginPage';
import ProtectedRoute from 'components/ProtectedRoute';
import VendorLogin from 'components/VendorLogin';
import AuthContext from 'context/Auth';
import UserContext from 'context/User';
import App from 'App';
import LoginMessage from 'components/LoginMessage';
import * as _ from 'lodash';
import getStoreData from 'helpers/StoreManagement';
import VENDOR_ID_SESSION from 'constants/SessionManagementDetails';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'helpers/QueryUtils';
import initiateNotificationTrigger from './components/SessionExpireNotification';
import { getUser } from './helpers/UserUtil';

// Keep primary scss file at the bottom of the import list to avoid library overrides of styles
import 'styles/App.scss';

const logger = new Logger('Auth-Logger');

function MainApp() {
  const [currentUser, setCurrentUser] = useState({});
  const [userRole, setUserRole] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSettingsLoading, setSettingsLoading] = useState(false);

  const [userDetails, setUserDetails] = useState({});
  const [vendorList, setVendorList] = useState([]);
  const [isVendorDropdownVisible, setVendorDropdownVisible] = useState(false);
  const [isVendorDropdownDisabled, setVendorDropdownDisabled] = useState(false);
  const [isVendorStoring, setIsVendorStoring] = useState(false);

  // set contact support state
  const [isSupportContactVisible, setIsSupportContactVisible] = useState(false);
  const [supportContactDetails, setSupportContactDetails] = useState([]);

  const [selectedVendor, setSelectedVendor] = useState({
    vendorId: null,
    vendorName: null
  });

  const handleSetVendorDropdownVisible = value => {
    setVendorDropdownVisible(value);
  };

  const handleSetVendorDropdownDisable = value => {
    setVendorDropdownDisabled(value);
  };

  const handleSetIsSupportContactVisible = value => {
    setIsSupportContactVisible(value);
  };

  const handleSetSupportContactDetails = contacts => {
    if (!_.isEqual(contacts, supportContactDetails)) setSupportContactDetails(contacts);
  };

  const handleSetVendorList = vendors => {
    const storedVendorId = getStoreData(VENDOR_ID_SESSION);
    setVendorList(vendors);

    if (vendors?.length && !_.isEqual(_.sortBy(vendorList), _.sortBy(vendors))) {
      const storedVendor = vendors.find(vendor => vendor.vendorId === storedVendorId);

      // if stored vendor fetching eligible from session storage
      if (isVendorStoring && storedVendor) setSelectedVendor(storedVendor);
      else setSelectedVendor(vendors[0]);
    }
  };

  const handleUserRole = role => {
    /**
     * Landing UI is configured in a generic way to accept role as an object (to support Logon Messages feature).
     * Some micro apps only send the name of the user role (not an object)
     */
    if (_.isString(role)) {
      setUserRole({ roleName: role });
    } else {
      setUserRole(role);
    }
  };

  const handleSelectedVendor = vendor => {
    setSelectedVendor(vendor);
  };

  const setUserData = useCallback(async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      setSettingsLoading(true);
      const userData = await getUser(data);
      const { details } = userData;
      setUserDetails(details);
      setSettingsLoading(false);
    } catch (error) {
      setSettingsLoading(false);
      // eslint-disable-next-line no-console
      console.log('User Auth Details fetching:', error);
    }
  }, [setUserDetails]);

  const updateCurrentUser = useCallback(
    async user => {
      if (user) {
        setCurrentUser(user);
        setUserData();
        return;
      }
      try {
        const newUser = await Auth.currentAuthenticatedUser();
        setCurrentUser(newUser);
        setIsLoaded(true);
      } catch (err) {
        setCurrentUser({});
        setIsLoaded(true);
      }
    },
    [setCurrentUser, setIsLoaded, setUserData]
  );

  useEffect(() => {
    updateCurrentUser();

    const listener = data => {
      switch (data.payload.event) {
        case 'signIn':
          updateCurrentUser(data.payload.data);
          initiateNotificationTrigger();
          break;
        case 'signOut':
          updateCurrentUser();
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          break;
        default:
          logger.info(data.payload.event);
      }
    };

    if (isLoaded) Hub.listen('auth', listener);
  }, [isLoaded, updateCurrentUser]);

  useEffect(() => {
    setUserData();
  }, [setUserData]);

  const getUserContextValue = useCallback(
    () => ({
      userDetails,
      userRole,
      selectedVendor,
      isSettingsLoading,
      vendorList,
      isVendorDropdownVisible,
      isVendorDropdownDisabled
    }),
    [
      userDetails,
      userRole,
      selectedVendor,
      isSettingsLoading,
      vendorList,
      isVendorDropdownVisible,
      isVendorDropdownDisabled
    ]
  );

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userRole,
        updateCurrentUser,
        isLoaded
      }}
    >
      <UserContext.Provider value={getUserContextValue()}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <ProtectedRoute path="/login" component={LoginPage} type="LoginRoute" />
              <ProtectedRoute path="/vendorlogin" component={VendorLogin} type="LoginRoute" />
              <ProtectedRoute
                path="/suite"
                component={App}
                type="PrivateRoute"
                setSelectedVendor={handleSelectedVendor}
                setVendorList={handleSetVendorList}
                setVendorDropdownVisible={handleSetVendorDropdownVisible}
                userRole={userRole}
                setUserRole={handleUserRole}
                setVendorDropdownDisable={handleSetVendorDropdownDisable}
                setIsVendorStoring={setIsVendorStoring}
                isSupportContactVisible={isSupportContactVisible}
                setIsSupportContactVisible={handleSetIsSupportContactVisible}
                supportContactDetails={supportContactDetails}
                setSupportContactDetails={handleSetSupportContactDetails}
              />
              <ProtectedRoute path="/error" component={LoginMessage} type="ErrorRoute" />
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              <Route path="*" component={LoginPage} />
            </Switch>
          </Router>
        </QueryClientProvider>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
}
export default MainApp;
