import i18n from '_i18n';
import openNotification from 'components/Notification';

const openErrorNotification = ({ message, description }) => {
  openNotification({
    message: i18n.t(message),
    description,
    className: 'error'
  });
};

export default openErrorNotification;
