import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import userContext from 'context/User';
import { setStoreData } from 'helpers/StoreManagement';
import VENDOR_ID_SESSION from 'constants/SessionManagementDetails';

export class VendorSwitch extends React.Component {
  state = {
    visible: false
  };

  toggleMenu = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  selectVendorHandler = vendor => {
    const { selectVendor } = this.props;

    setStoreData(VENDOR_ID_SESSION, vendor.vendorId);
    selectVendor(vendor);
  };

  static contextType = userContext;

  render() {
    const { visible } = this.state;
    const { selectedVendor, isVendorDropdownVisible, isVendorDropdownDisabled, vendorList } = this.context;
    const { t } = this.props;

    if (!isVendorDropdownVisible) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          role="button"
          tabIndex="0"
          className="organisation"
          onClick={() => !isVendorDropdownDisabled && this.toggleMenu()}
          onKeyPress={() => !isVendorDropdownDisabled && this.toggleMenu()}
        >
          <div className="current-vendor">
            <div className="type">{t('landing.vendor')}</div>
            <React.Fragment>
              {selectedVendor.vendorId && selectedVendor.vendorName ? (
                <div
                  id="vendorname"
                  className="name"
                  title={`${selectedVendor.vendorId} - ${selectedVendor.vendorName}`}
                >
                  {`${selectedVendor.vendorId} - ${selectedVendor.vendorName}`}
                </div>
              ) : (
                <div id="noVendorsMessage" className="name">
                  {t('landing.vendor.notAvailable')}
                </div>
              )}
              {!isVendorDropdownDisabled && <i className="fi flaticon-caret-down"></i>}
            </React.Fragment>
          </div>
          <ul
            id="vendorlist"
            className={visible && !isVendorDropdownDisabled ? 'vendor-switcher show' : 'vendor-switcher'}
          >
            {vendorList.length > 0 &&
              vendorList.map(vendor => (
                <li key={vendor.vendorId} onClick={() => this.selectVendorHandler(vendor)}>
                  {`${vendor.vendorId} - ${vendor.vendorName}`}
                </li>
              ))}
          </ul>
        </div>
        {visible && !isVendorDropdownDisabled && (
          <div role="button" tabIndex="-4" className="backgroundClickContainer" onClick={this.toggleMenu} />
        )}
      </React.Fragment>
    );
  }
}

VendorSwitch.propTypes = {
  selectVendor: PropTypes.func.isRequired
};
export default withTranslation()(VendorSwitch);
