import { QueryClient } from 'react-query';
import Axios from 'axios';
import { baseURL, getRequestHeaders } from './UserUtil';

/**
 * This file contains all async functions used in useQuery and useMutation hooks in react-query library
 */

// create query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // disable refetching queries in the background, at window refocus events
      refetchOnWindowFocus: false
    }
  }
});

// Async function used to fetch Logon messages related to a user
async function getUserApplicationMessages({ queryKey }) {
  const [roleId] = queryKey;
  if (roleId) {
    const userMessageDetails = await Axios.get(`${baseURL}/logon-messages/user?roleId=${roleId}`, {
      headers: await getRequestHeaders()
    });

    return userMessageDetails.data;
  }
  return [];
}

// Async function used to mark logon messages as read by user
async function markLogonMessagesAsRead(messageIds) {
  const { data: response } = await Axios.post(`${baseURL}/logon-messages/markAsRead`, messageIds, {
    headers: await getRequestHeaders()
  });
  return response;
}

export { queryClient, getUserApplicationMessages, markLogonMessagesAsRead };
