import { isEmpty } from 'lodash';
import i18n from '_i18n';
import { USER_MANAGEMENT_ADMIN, APPS_MAINTENANCE_VIEW_DETAILS } from 'constants/Maintenance';

/**
 * Logic to display a Maintenance View on the loaded application
 * @param {Object} userDetails user details including UMS user role of the user
 * @param {Object} appDetail app details including the path to the application
 * @returns {boolean} whether or not to display a Maintenance View on the application
 */
const shouldDisplayMaintenanceView = (userDetails, appDetail) => {
  return (
    !isEmpty(userDetails) &&
    userDetails.userRole !== USER_MANAGEMENT_ADMIN &&
    APPS_MAINTENANCE_VIEW_DETAILS.appPathsUnderMaintenance.includes(appDetail.path)
  );
};

const getAppMaintenanceMessage = () => {
  const selectedLanguage = i18n.language;
  return APPS_MAINTENANCE_VIEW_DETAILS.maintenanceMessages[selectedLanguage];
};

export { shouldDisplayMaintenanceView, getAppMaintenanceMessage };
