import React from 'react';
import { Button, notification } from 'antd';
import { Auth } from 'aws-amplify';
import i18n from '_i18n';

const openNotification = delay => {
  setTimeout(() => {
    return new Promise(() => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={async () => {
            await Auth.signOut();
            notification.close(key);
          }}
        >
          Sign In
        </Button>
      );
      notification.open({
        message: i18n.t('landing.notification.sessionExpireHeader'),
        description: i18n.t('landing.notification.sessionExpireContent'),
        btn,
        key
      });
    });
  }, delay);
};

const initiateSessionExpireNotificationTrigger = async () => {
  const session = await Auth.currentSession();
  const delay = session.getAccessToken().getExpiration() * 1000 - Date.now();
  openNotification(delay);
};

export default initiateSessionExpireNotificationTrigger;
