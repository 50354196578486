/**
 * get environment label to display in top right corner.
 */
const getEnvLabel = () => {
  let envLogo;
  switch (process.env.REACT_APP_ENV) {
    case 'qa':
      envLogo = 'qa-env';
      break;
    case 'dev':
      envLogo = 'dev-env';
      break;
    case 'prod':
      envLogo = 'prod-env';
      break;
    case 'uat':
      envLogo = 'uat-env';
      break;
    default:
      envLogo = '';
      break;
  }
  return envLogo;
};

export default getEnvLabel;
