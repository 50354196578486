/* eslint-disable import/prefer-default-export */
import { markLogonMessagesAsRead } from 'helpers/QueryUtils';
import { useMutation } from 'react-query';

/**
 * This file contains Custom Hooks that involve `react-query` mutation hooks
 */

function mutateLogonMessageMarkAsRead() {
  const { mutate: markMessagesAsRead } = useMutation(markLogonMessagesAsRead);

  return { markMessagesAsRead };
}
export { mutateLogonMessageMarkAsRead };
