import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { split } from 'lodash';
import getEnvLabel from 'helpers/GetEnvLabel';
import { LOGIN_LOCAL_STORAGE_LANGUAGE_KEY, SUPPORTED_LANGUAGES } from 'constants/Language';
import { getFromLocalStorage, setToLocalStorage } from 'helpers/LocalStorageHelper';
import logo from '../styles/images/logo.svg';

function handleClick(identityProvider) {
  // do something meaningful, Promises, if/else, whatever, and then
  // Auth.federatedSignIn({ provider: 'adprovider', customState: 'nadun' });
  // TODO: remove the bypass
  window.location.href = `https://${process.env.REACT_APP_DOMAIN}/oauth2/authorize?redirect_uri=${process.env.REACT_APP_REDIRECT_SIGNIN}&response_type=TOKEN&client_id=${process.env.REACT_APP_USER_CLIENT_ID}&identity_provider=${identityProvider}&scopes=email%2Copenid`;
}

const envLogo = getEnvLabel();

function LoginPage() {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const savedLanguage = getFromLocalStorage(LOGIN_LOCAL_STORAGE_LANGUAGE_KEY);

    if (SUPPORTED_LANGUAGES.includes(savedLanguage)) {
      i18n.changeLanguage(savedLanguage);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;

      // Take general language without any specialization, e.g - take en from en-US
      const generalBrowserLanguage = browserLanguage ? split(browserLanguage, '-')[0] : 'en';

      if (SUPPORTED_LANGUAGES.includes(generalBrowserLanguage)) {
        i18n.changeLanguage(generalBrowserLanguage);
      }
    }
  }, []);

  const handleLanguageChange = languague => {
    setToLocalStorage(LOGIN_LOCAL_STORAGE_LANGUAGE_KEY, languague);
    i18n.changeLanguage(languague);
  };

  return (
    <div className={`wrapper login-wrapper ${envLogo}`}>
      <div className="login-panel">
        <div className="language-selector-wrapper">
          <Select
            value={i18n.language}
            className="language-selector"
            bordered={false}
            suffixIcon={<span className="icon fi flaticon-subject" />}
            onChange={handleLanguageChange}
            options={[
              { value: 'en', label: t('landing.english') },
              { value: 'fr', label: t('landing.french') }
            ]}
          />
        </div>
        <div className="user-selection">
          <img src={logo} alt="Sysco Supplier Suite" className="logo" />
          <div className="title">{t('landing.welcome')}</div>
          <div className="subtitle">{t('landing.login.userSelect')}</div>

          <ul className="selection">
            <li onClick={() => handleClick(process.env.REACT_APP_IDENTITY_PROVIDER_OKTA)}>
              <div className="icon vendor fi flaticon-diet" />
              <div className="type">{t('landing.vendor')}</div>
            </li>
            <li onClick={() => handleClick(process.env.REACT_APP_IDENTITY_PROVIDER_AD_PROVIDER)}>
              <div className="icon associate fi flaticon-manager" />
              <div className="type">{t('landing.syscoColleague')}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
