import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ParcelComponent from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import ErrorBoundary from 'helpers/ErrorBoundary';
import AppBar from 'components/AppBar/AppBar';
import AppLoader from 'components/AppLoader';
import { Auth } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import UserContext from 'context/User';
import getLanguage from 'helpers/LanguageSelector';
import { shouldDisplayMaintenanceView } from 'helpers/MaintenanceChecker';
import MaintenanceView from 'components/MaintenanceView';
import LogonMessage from 'components/LogonMessage';
import queryApplicationMessages from 'hooks/useQueryHooks';
import { mutateLogonMessageMarkAsRead } from 'hooks/mutationHooks';

const appDetails = [
  {
    path: '/suite/dashboard',
    remoteImport: '@portal/dashboard'
  },
  {
    path: '/suite/user-management',
    remoteImport: '@portal/user-management'
  },
  {
    path: '/suite/item-enrichment',
    remoteImport: '@portal/item-enrichment'
  },
  {
    path: '/suite/costing',
    remoteImport: '@portal/costing'
  },
  {
    path: '/suite/claims',
    remoteImport: '@portal/claims'
  },
  {
    path: '/suite/rewards',
    remoteImport: '@portal/rewards'
  },
  {
    path: '/suite/osd',
    remoteImport: '@portal/osd'
  },
  {
    path: '/suite/help',
    remoteImport: '@portal/help'
  }
];

function App({
  i18n,
  setSelectedVendor,
  setVendorList,
  setVendorDropdownVisible,
  userRole,
  setUserRole,
  setVendorDropdownDisable,
  setIsVendorStoring,
  isSupportContactVisible,
  setIsSupportContactVisible,
  supportContactDetails,
  setSupportContactDetails
}) {
  const { userDetails, selectedVendor, isSettingsLoading } = useContext(UserContext);

  // eslint-disable-next-line no-undef
  const remoteImport = async url => (await SystemJS.import(url)).default;
  const { language } = i18n;
  const { vendorId } = selectedVendor;

  const { applicationMessages, isLoading: isApplicationMessagesFetching } = queryApplicationMessages(userRole?.roleId);
  const { markMessagesAsRead } = mutateLogonMessageMarkAsRead();

  useEffect(() => {
    i18n.changeLanguage(getLanguage(userDetails.language));
  }, [i18n, userDetails.language]);

  return (
    <div className="app-container main-container">
      {isSettingsLoading && <AppLoader />}
      <AppBar
        userDetails={userDetails}
        selectVendor={setSelectedVendor}
        selectedVendor={selectedVendor}
        isSupportContactVisible={isSupportContactVisible}
        supportContactDetails={supportContactDetails}
      />

      <ErrorBoundary>
        <Router>
          {appDetails.map(appDetail => (
            <Route
              key={appDetail.path}
              path={appDetail.path}
              render={() => {
                return shouldDisplayMaintenanceView(userDetails, appDetail) ? (
                  <MaintenanceView />
                ) : (
                  <>
                    <ParcelComponent
                      config={remoteImport(appDetail.remoteImport)}
                      mountParcel={mountRootParcel}
                      Auth={Auth}
                      wrapWith="section"
                      vendorId={vendorId}
                      language={language}
                      className="app-container"
                      setVendorList={setVendorList}
                      setVendorDropdownVisible={setVendorDropdownVisible}
                      setVendorDropdownDisable={setVendorDropdownDisable}
                      setUserRole={setUserRole}
                      setIsVendorStoring={setIsVendorStoring}
                      setIsSupportContactVisible={setIsSupportContactVisible}
                      setSupportContactDetails={setSupportContactDetails}
                    />
                    {!isSettingsLoading && !isApplicationMessagesFetching && applicationMessages?.length > 0 && (
                      <LogonMessage
                        messages={applicationMessages}
                        language={language}
                        markMessagesAsRead={markMessagesAsRead}
                      />
                    )}
                  </>
                );
              }}
            />
          ))}
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default withTranslation()(App);
