import { ArrowLeftOutlined, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Carousel, Checkbox, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function LogonMessage({ messages, language, markMessagesAsRead }) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(true);

  const carouselRef = useRef();
  const messageCount = messages.length;
  const showNavigationButtons = messageCount > 1;
  const markedMessageIds = [];

  const next = () => {
    carouselRef.current?.next();
  };

  const prev = () => {
    carouselRef.current?.prev();
  };

  const modalClose = () => {
    if (markedMessageIds?.length > 0) {
      markMessagesAsRead(markedMessageIds);
    }
    setModalOpen(false);
  };

  const onChangeDoNotShowStatus = (checked, messageId) => {
    // keep track of messageIds that are marked as not to be shown again
    if (checked) {
      markedMessageIds.push(messageId);
    } else {
      markedMessageIds.splice(markedMessageIds.indexOf(messageId), 1);
    }

    // maintain a small delay before showing the next message
    setTimeout(next, 500);
  };

  const checkAbilityToCloseModal = () => {
    // close modal if all messages are marked to be not shown again
    if (markedMessageIds.length === messageCount) modalClose();
  };

  return (
    // zIndex is set to 10001 as apploader has zIndex of 10000
    <Modal
      id="logon-message-modal"
      centered
      open={modalOpen}
      onCancel={modalClose}
      maskClosable={false}
      className="modal-container"
      width={550}
      zIndex={10001}
      footer={[
        <div className="ok-btn">
          <Button id="back-to-app" shape="round" onClick={modalClose} className="back-to-app-btn">
            {t('landing.logonMessage.backToApp')}
          </Button>
        </div>
      ]}
    >
      <div className="modal-body">
        {showNavigationButtons && (
          <Button id="prev-btn" className="navigation-btn" onClick={prev}>
            <div>
              <ArrowLeftOutlined />
            </div>
            <div>{t('landing.logonMessage.previous')}</div>
          </Button>
        )}

        <Carousel
          afterChange={checkAbilityToCloseModal}
          dots
          ref={carouselRef}
          adaptiveHeight
          className="carousel-container"
          id="logon-message-carousel"
        >
          {messages.map(message => (
            <div key={message.messageId}>
              <div>
                <InfoCircleOutlined className="info-icon" />
              </div>
              <div className="message-title">{language === 'en' ? message.titleEn : message.titleFr}</div>
              <div className="message-content">{language === 'en' ? message.messageEn : message.messageFr}</div>
              <div className="message-do-not-show-section">
                <Checkbox
                  id="do-not-show-again-checkbox"
                  onChange={e => onChangeDoNotShowStatus(e.target.checked, message.messageId)}
                >
                  {t('landing.logonMessage.doNotShowAgain')}
                </Checkbox>
              </div>
              <span className="vertical-spacer"></span>
            </div>
          ))}
        </Carousel>
        {showNavigationButtons && (
          <Button id="next-btn" className="navigation-btn" onClick={next} shape="round">
            <div>
              <ArrowRightOutlined />
            </div>
            <div>{t('landing.logonMessage.next')}</div>
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default LogonMessage;
