const ampConfig = {
  Auth: {
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
      responseType: 'TOKEN'
    },
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_CLIENT_ID,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: process.env.REACT_APP_S3_LANDING_UI_DOMAIN,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'strict',
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: process.env.NODE_ENV !== 'development'
    }
  }
};

export default ampConfig;
