import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import MaintenanceBackground from 'styles/images/maintenance.jpg';
import { getAppMaintenanceMessage } from 'helpers/MaintenanceChecker';

export default function MaintenanceView() {
  const { t } = useTranslation();

  return (
    <>
      <div className="maintenance-wrap">
        <img src={MaintenanceBackground} id="maintenance" className="maintenance-bg" alt="" />

        <h2 className="maintenance-h2">{t('landing.maintenance.header')}</h2>
        <div id="downtime-message" className="maintenance-message">
          {getAppMaintenanceMessage()}
        </div>

        <a href="/suite/dashboard">
          <Button id="go-back-btn" type="primary" className="btn btn-go-back ">
            <i className="fi flaticon-arrow"></i>
            {t('landing.maintenance.backToDashboard')}
          </Button>
        </a>
      </div>
    </>
  );
}
