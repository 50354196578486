import React from 'react';
import PropTypes from 'prop-types';
import AppBar from 'components/AppBar/AppBar';
import logo from '../styles/images/logo.svg';
import errorImg from '../styles/images/error.png';
import noRoleImg from '../styles/images/error-norole.png';

function LoginMessage({ isNoRoleError, location }) {
  const isNoRoleErrorSwitcher = isNoRoleError || location.isNoRoleError;
  const getErrorDetails = () =>
    isNoRoleErrorSwitcher ? (
      <React.Fragment>
        <img src={errorImg} alt="Sorry your not ready yet." className="errorimg norole" />
        <div className="subtitle-b">
          Looks like there are a few more things we need to do from our end to get you started - like assigning you a
          proper user role. Please contact support services for more details.
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <img src={noRoleImg} alt="Sorry your not ready yet." className="errorimg" />
        <div className="subtitle-b">
          Hi there! We are experiencing some technical issues at the moment and would be back momentarily. Please try
          again later and if the issue persists, contact support services.
        </div>
      </React.Fragment>
    );
  return (
    <React.Fragment>
      <AppBar />
      <div className="wrapper login-wrapper">
        <div className="login-panel login-message">
          <img src={logo} alt="Sysco Supplier Suite" className="logo" />
          {getErrorDetails()}
        </div>
      </div>
    </React.Fragment>
  );
}

LoginMessage.propTypes = {
  isNoRoleError: PropTypes.bool.isRequired
};
export default LoginMessage;
